import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  topyellowbar: {
    width: '100%',
    height: '18px',
    backgroundColor: '#FFE200',
  },
  logo: {
    '& img': {
      width: '240px',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
        marginBottom: theme.spacing(1),
      },
    }
  },
  headerLeft: {
    textAlign: 'left',
    fontSize: '12px',
    marginTop: theme.spacing(3),
    '& p:first-child': {
      marginTop: theme.spacing(2),
    },
    '& p:last-child': {
      marginTop: '-8px',
    },
  },
  headerRight: {
    textAlign: 'right',
    fontSize: '12px',
    marginTop: theme.spacing(3),
    '& p:first-child': {
      marginTop: '16px',
    },
    '& p:last-child': {
      marginTop: '-8px',
    },
  },
  hero: {
    '& img': {
      width: '100%',
    },
  },
  intro: {
    padding: '0 140px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  h1: {
    fontSize: '60px',
    fontFamily: 'Colfax Bold',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
      lineHeight: '3rem',
      paddingTop: theme.spacing(2),
      letterSpacing: '0rem',
    },
  },
  h2: {
    fontSize: '24px',
    fontFamily: 'Colfax Light',
    lineHeight: '2.2rem',
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '2.2rem',
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
    },
  },

  callout: {
    textAlign: 'center',
    '& div': {
      textTransform: 'uppercase',
      fontSize: '32px',
      fontFamily: 'Colfax Medium',
      letterSpacing: '0.1rem',
    },
    '& div:last-child': {
      textTransform: 'uppercase',
      fontSize: '56px',
      fontFamily: 'Colfax Bold',
      letterSpacing: '0rem',
      marginTop: theme.spacing(1),
    },
  },
  services: {
    marginTop: theme.spacing(3),
    '& img': {
      width: '100px',
      height: '100px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '36px',
        marginTop: theme.spacing(2),
      },
    },
    '& p': {
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '1.4rem',
      padding: '0 32px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '1.8rem',
      },
    },
    '& li': {
      marginBottom: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      // fontFamily: 'Colfax Medium',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        marginLeft: '30%',
        width: '100%',
        fontFamily: 'Colfax Regular',
      },
    },
  },
  serviceImg: {
    paddingTop: theme.spacing(6),
    width: '100%',
    textAlign: 'center',
  },
  serviceTitle: {
    fontSize: '22px',
    fontFamily: 'Colfax Bold',
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
    textAlign: 'center',
    // padding: '0 auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },

  reviews: {
    textAlign: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
    '& p:first-child': {
      fontSize: '24px',
      fontFamily: 'Colfax Bold',
      marginBottom: '-10px',
    },
    '& img': {
      width: '199px',
      height: '47px',
    },
  },

  yellowCallout: {
    backgroundColor: '#FFE200',
    width: 'strech',
    borderRadius: theme.spacing(6),
    textAlign: 'center',
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(1),
    },
    '& div': {
      textTransform: 'uppercase',
      fontSize: '24px',
      fontFamily: 'Colfax Medium',
      letterSpacing: '0.2rem',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        marginTop: theme.spacing(1),
        letterSpacing: '0.1rem',
      },
    },
    '& div:last-child': {
      textTransform: 'uppercase',
      fontSize: '56px',
      fontFamily: 'Colfax Bold',
      letterSpacing: '0rem',
      marginTop: theme.spacing(1),
      color: '#111111',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0.5),
        fontSize: '32px',
      },
    },
  },
  medeco: {
    textAlign: 'center',
    marginTop: theme.spacing(8),
    '& img': {
      width: '150px',
      height: '49px',
      marginTop: theme.spacing(-1),
    },
  },
  footer: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
    fontSize: '12px',
    color: '#777777',
    '& p': {
      marginBottom: theme.spacing(-1),
    }
  },
}));

export default useStyles;