import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden';
import AstrokeyLogo from './images/logo2@2x.png'
import CommercialImg from './images/commercial@2x.png'
import ResidentialImg from './images/residential@2x.png'
import AutomotiveImg from './images/automotive@2x.png'
import MedecoImg from './images/medeco@2x.png'
import Hero1 from './images/hero1.jpg'
import Hero2 from './images/hero2.jpg'
import Hero3 from './images/hero3.jpg'
import Hero4 from './images/hero4.jpg'
import Hero5 from './images/hero5.jpg'
import Reviews from './images/reviews@2x.png'
import Slider from 'infinite-react-carousel';
import useStyles from './styles/main';

function App() {
  const classes = useStyles();
  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    swipe: false,
    pauseOnHover: false,
  };

  const SimpleSlider = () => (
    <Slider dots {...settings}>
      <div>
        <img src={Hero1} alt="Hero" />
      </div>
      <div>
        <img src={Hero2} alt="Hero" />
      </div>
      <div>
        <img src={Hero3} alt="Hero" />
      </div>
      <div>
        <img src={Hero4} alt="Hero" />
      </div>
      <div>
        <img src={Hero5} alt="Hero" />
      </div>
    </Slider>
  );
  return (
    <div className="root">
      <Box mb={5} className={classes.topyellowbar}></Box>
      <Container>
        <Grid container>
          <Grid container sm={4}>
            <Hidden smDown>
              <Box className={classes.headerLeft}>
                <p>LICENSED, BONDED, INSURED</p>
                <p style={{ fontSize: '24px', fontFamily: 'Colfax Medium' }}>LR258</p>
              </Box>
            </Hidden>
          </Grid>
          <Grid container sm={4} justify="center">
            <div className={classes.logo}><img src={AstrokeyLogo} alt="Astrokey Locksmith" /></div>
          </Grid>
          <Grid container sm={4} justify="flex-end">
            <Hidden smDown>
              <Box className={classes.headerRight}>
                <p>OPEN 24 HOURS</p>
                <p style={{ fontSize: '24px', fontFamily: 'Colfax Medium' }}><a style={{ textDecoration: 'none', color: '#000000' }} href="tel:+1-305-385-0017">(305) 385-0017</a></p>
              </Box>
            </Hidden>
          </Grid>
        </Grid>

        <Hidden smUp>
          <Box mt={0} className={classes.yellowCallout}>
            <div className={classes.title}>OPEN 24 HOURS</div>
            <div><a style={{ textDecoration: 'none', color: '#000000' }} href="tel:+1-305-385-0017">(305) 385-0017</a></div>
          </Box>
        </Hidden>

        <Box className={classes.hero} mt={5}>
          {/* <img src={Hero} alt="Hero" /> */}
          <SimpleSlider />
        </Box>

        <Box className={classes.intro}>
          <h1 className={classes.h1}>Serving all Miami Dade County for over 20 years</h1>
          <h2 className={classes.h2} style={{ marginTop: '-16px' }}>Commited to bring our clients the highest quality locksmith services in any type of project. We have experienced and reliable technicians and whether your problem is residential, commercial, or automotive, we can help.</h2>
        </Box>

        <Hidden smDown>
          <Box className={classes.callout}>
            <div className={classes.title}>Open 24 Hrs.</div>
            <div><a style={{ textDecoration: 'none', color: '#000000' }} href="tel:+1-305-385-0017">(305) 385-0017</a></div>
          </Box>
        </Hidden>

        <Box className={classes.services}>
          <Grid container>
            <Grid container md={4} direction="column" alignItems="flex-start" className={classes.serviceItem}>
              <div className={classes.serviceImg}><img src={CommercialImg} alt="Commercial" /></div>
              <div className={classes.serviceTitle}>Commercial</div>
              <p>Whether you are a small business or a large corporation you will get the most advanced security. Lock re-keying,  installation, and replacement.</p>
              <ul>
                <li>Master Key Systems</li>
                <li>High Security Locks</li>
                <li>File Cabinet Locks</li>
                <li>Access Controls</li>
                <li>Mailbox Specialties</li>
              </ul>
            </Grid>
            <Grid container md={4} direction="column" alignItems="flex-start">
              <div className={classes.serviceImg}><img src={ResidentialImg} alt="Residential" /></div>
              <div className={classes.serviceTitle}>Residential</div>
              <p>We provide personalized services to a range of lock and key solutions for your home at fair prices. We repair any types of locks, safes, doors, and gates.</p>
              <ul>
                <li>House Lockout</li>
                <li>Lock Rekey & Change</li>
                <li>Lock Installation</li>
                <li>Broken Key Extraction</li>
                <li>Safe Lockout</li>
              </ul>
            </Grid>
            <Grid container md={4} direction="column" alignItems="flex-start">
              <div className={classes.serviceImg}><img src={AutomotiveImg} alt="Automotive" /></div>
              <div className={classes.serviceTitle}>Automotive</div>
              <p>You can depend on us to get you back on the road quickly whether is a car, motorcycle or truck. Regular and smart digital ingnition key systems.</p>
              <ul>
                <li>Car Lockout</li>
                <li>Car Key Making</li>
                <li>Car Key Extraction</li>
                <li>Key Fob Replacement</li>
                <li>Trunk Lockout</li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.reviews}>
          <p>We are committed to excellence</p>
          <p>Read what our customers say about us.</p>
          <p><img src={Reviews} alt="4.9 stars" /></p>

          <Button style={{ margin: '4px' }} variant="contained" color="primary" href="https://g.co/kgs/DEuUjF">Read Reviews</Button>

          <Button style={{ margin: '4px' }} variant="contained" color="primary" href="https://g.co/kgs/JmzLwB">Write a Review</Button>
        </Box>
        <a style={{ textDecoration: 'none', color: '#000000' }} href="tel:+1-305-385-0017">
          <Box className={classes.yellowCallout}>
            <div className={classes.title}>Call Us Today</div>
            <div>(305) 385-0017</div>
          </Box>
        </a>

        <Box className={classes.medeco}>
          <p>AUTHORIZED DEALER OF MEDECO</p>
          <img src={MedecoImg} alt="medeco" />
        </Box>

        <Box className={classes.footer}>
          <p>LR258 - Licensed, Bonded, Insured.</p>
          <p>&copy; 2020 AstroKey Locksmith</p>
        </Box>



      </Container>
    </div >
  );
}

export default App;
